export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    Any: any
    Time: Date
}

export enum AccountType {
    IBAN = 'IBAN',
    TRANSFER = 'TRANSFER',
}

export type Address = {
    __typename?: 'Address'
    addition?: Maybe<Scalars['String']>
    city: Scalars['String']
    houseNumber: Scalars['String']
    street: Scalars['String']
    zipcode: Scalars['String']
}

export type AddressData = {
    addition?: Maybe<Scalars['String']>
    city: Scalars['String']
    houseNumber: Scalars['String']
    street: Scalars['String']
    zipcode: Scalars['String']
}

export type AvailableProductsResponse = {
    __typename?: 'AvailableProductsResponse'
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    ProductCategories: Array<ProductCategory>
}

export type BankDetailsData = {
    accountType: AccountType
    differentAccountHolder: Scalars['Boolean']
    consentChecked: Scalars['Boolean']
    accountHolderData: PersonalData
    iban: Scalars['String']
    dayOfTransfer: Scalars['String']
    transfer: Scalars['Boolean']
}

export enum BillMode {
    ONE_TIME = 'ONE_TIME',
    RECURRING_MONTHLY = 'RECURRING_MONTHLY',
    IGNORE = 'IGNORE',
    VOUCHER = 'VOUCHER',
    RECURRING_MONTHLY_PP = 'RECURRING_MONTHLY_PP',
    RECURRING_QUARTERLY_PP = 'RECURRING_QUARTERLY_PP',
    RECURRING_HALF_YEARLY_PP = 'RECURRING_HALF_YEARLY_PP',
    RECURRING_YEARLY_PP = 'RECURRING_YEARLY_PP',
}

export type Booking = {
    __typename?: 'Booking'
    bookingNumber: Scalars['String']
    orderDate: Scalars['String']
    contractor: Scalars['String']
    address: Scalars['String']
}

export type CheckVzfResponse = {
    __typename?: 'CheckVZFResponse'
    isValid: Scalars['Boolean']
    vzf?: Maybe<VzfData>
    vzfID?: Maybe<Scalars['String']>
}

export type CitiesResponse = {
    __typename?: 'CitiesResponse'
    status: Scalars['String']
    cities: Array<Scalars['String']>
}

export type CompleteOrderResponse = {
    __typename?: 'CompleteOrderResponse'
    success: Scalars['Boolean']
    orderNumber: Scalars['String']
}

export type ConfigurationItem = {
    key: Scalars['String']
    value: Scalars['String']
}

export type ContractData = {
    desiredDate: Scalars['String']
    earliestDatePossible: boolean
    installationDetails?: Maybe<InstallationDetailsState>
    configuration: Array<ConfigurationItem>
    selectedProductCategories: Array<SelectedProductCategory>
}

export type Discount = {
    __typename?: 'Discount'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description: Scalars['String']
    descriptionBasket: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    monthlyDiscounts: Array<MonthPrice>
    discountLength: Scalars['Int']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    content: Scalars['String']
    messages?: Maybe<Array<Scalars['String']>>
    title: Scalars['String']
}

export type ExtraPersonalData = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthDate: Scalars['String']
    telephone: Scalars['String']
    mobilePhone: Scalars['String']
    orderComment: Scalars['String']
    useSalesPartnerEmail: Scalars['Boolean']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    companyRegisterEntry: Scalars['String']
    companyLocation: Scalars['String']
    companyId: Scalars['String']
}

export type Fee = {
    __typename?: 'Fee'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description: Scalars['String']
    descriptionBasket: Scalars['String']
    billMode: BillMode
    included: Scalars['Boolean']
    optional: Scalars['Boolean']
    processUnique: Scalars['Boolean']
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    feeLength: Scalars['Int']
    monthlyFees: Array<MonthPrice>
    discounts: Array<Discount>
}

export type FooterContent = {
    name: Scalars['String']
    page: Scalars['Int']
}

export type InstallationDetailsState = {
    floorInformation: Scalars['String']
    flatPosition: Scalars['String']
    flatNumber: Scalars['String']
    junctionBox: Scalars['String']
    houseConnection: Scalars['String']
    houseConnectionInput: Scalars['String']
    opticFiber: Scalars['String']
    opticFiberInput: Scalars['String']
    installationService: Scalars['Float']
}

export type LoadOrderProgressResponse = {
    __typename?: 'LoadOrderProgressResponse'
    data: Scalars['String']
    vzfID: Scalars['String']
    vzfData: VzfData
}

export type Loadstate = {
    __typename?: 'Loadstate'
    errors: Array<Scalars['String']>
    loading: Scalars['Boolean']
}

export type LoggedUser = {
    __typename?: 'LoggedUser'
    email: Scalars['String']
    customerNumber: Scalars['String']
    name: Scalars['String']
    roles: Array<Scalars['String']>
}

export type Marketing = {
    __typename?: 'Marketing'
    id: Scalars['ID']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    addition?: Maybe<Scalars['String']>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
}

export type MarketingEditInput = {
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    ids: Array<Scalars['ID']>
}

export type MarketingInput = {
    id: Scalars['ID']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    addition?: Maybe<Scalars['String']>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
}

export type MonthPrice = {
    __typename?: 'MonthPrice'
    initMonth: Scalars['Int']
    endMonth: Scalars['Int']
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
}

export type MultipleSelectOptionItem = {
    key: Scalars['String']
    value: Scalars['Int']
}

export type MultipleSelectOptions = {
    __typename?: 'MultipleSelectOptions'
    key: Scalars['String']
    value: Scalars['Int']
}

export type Mutation = {
    __typename?: 'Mutation'
    completeOrderProgress: CompleteOrderResponse
    saveOrderProgress?: Maybe<Response>
    sendVZFMailAndSave: Response
    saveVZF: Response
    sendTouchPoint: Response
    editUser?: Maybe<Response>
    createUser?: Maybe<Response>
    deleteUser?: Maybe<Response>
    editVoucher?: Maybe<Response>
    createVoucher?: Maybe<Response>
    deleteVoucher?: Maybe<Response>
    bulkDeleteMarketing?: Maybe<Response>
    createMarketing?: Maybe<Response>
    editMarketing?: Maybe<Response>
    editProvisionings: Response
    createProvisionings: Response
}

export type MutationCompleteOrderProgressArgs = {
    order: OrderDataInput
    pdf: PdfDataInput
    vzfID: Scalars['String']
    vzfSummaryData: VzfpdfData
    B2B: Scalars['Boolean']
}

export type MutationSaveOrderProgressArgs = {
    email: Scalars['String']
    data: Scalars['String']
    key: Scalars['String']
    salutation: Scalars['String']
    vzfID: Scalars['String']
}

export type MutationSendVzfMailAndSaveArgs = {
    vzfID: Scalars['String']
    input: VzfDataInput
    pdfData: VzfpdfData
    B2B: Scalars['Boolean']
}

export type MutationSaveVzfArgs = {
    input: VzfDataInput
    vzfID: Scalars['String']
}

export type MutationSendTouchPointArgs = {
    input: TouchPointInput
}

export type MutationEditUserArgs = {
    input: UserInput
}

export type MutationCreateUserArgs = {
    input: UserInput
}

export type MutationDeleteUserArgs = {
    id: Scalars['String']
}

export type MutationEditVoucherArgs = {
    input: VoucherInput
}

export type MutationCreateVoucherArgs = {
    input: VoucherInput
}

export type MutationDeleteVoucherArgs = {
    id: Scalars['String']
}

export type MutationBulkDeleteMarketingArgs = {
    ids: Array<Scalars['String']>
}

export type MutationCreateMarketingArgs = {
    input: MarketingInput
}

export type MutationEditMarketingArgs = {
    input: MarketingEditInput
}

export type MutationEditProvisioningsArgs = {
    provisionings: Array<Scalars['ID']>
    state: Scalars['String']
}

export type MutationCreateProvisioningsArgs = {
    from: Scalars['Time']
    to: Scalars['Time']
}

export type OptEntry = {
    key: Scalars['String']
    value: Scalars['String']
}

export type Option = {
    __typename?: 'Option'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dataSheet?: Maybe<Scalars['String']>
    descriptionBasket: Scalars['String']
    badgeIdentifier?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    preconditionArticle: Array<Scalars['String']>
    excludedArticles: Array<Scalars['String']>
    requiredArticles: Array<Scalars['String']>
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    contractLength: Scalars['String']
    discounts: Array<Discount>
    fees: Array<Fee>
    max?: Maybe<Scalars['Int']>
    min?: Maybe<Scalars['Int']>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
    minimumContractPeriod?: Maybe<Scalars['String']>
    cancellationPeriod?: Maybe<Scalars['String']>
    contractExtension?: Maybe<Scalars['String']>
    isHardware: Scalars['Boolean']
}

export type OptionCategory = {
    __typename?: 'OptionCategory'
    id: Scalars['ID']
    title: Scalars['String']
    type: Scalars['String']
    identifier: Scalars['String']
    titleBasket: Scalars['String']
    information?: Maybe<Scalars['String']>
    max: Scalars['Int']
    min: Scalars['Int']
    options: Array<Option>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
}

export type OrderDataInput = {
    personalAddress: ExtraPersonalData
    deviatingDeliveryAddress: Scalars['Boolean']
    deviatingBillingAddress: Scalars['Boolean']
    deliveryAddress: PersonalData
    billingAddress: PersonalData
    bankDetails: BankDetailsData
    portability: PortabilityData
    contractData: ContractData
    opt: Array<OptEntry>
    multipleSelectOptionList: Array<MultipleSelectOptionItem>
    distributor: Scalars['String']
    vouchers: Array<VoucherDataInput>
    b2b: Scalars['Boolean']
}

export type OrderedProduct = {
    __typename?: 'OrderedProduct'
    name: Scalars['String']
    price: Scalars['Float']
}

export type PdfContent = {
    Key: Scalars['String']
    Value: Scalars['String']
    Description: Array<Scalars['String']>
}

export type PdfDataInput = {
    PDFSummaryEntries: Array<PdfEntry>
    costs: VzfpdfCost
}

export type PdfEntry = {
    Headline: Scalars['String']
    Content: Array<PdfContent>
}

export type PersonalData = {
    zipcode: Scalars['String']
    city: Scalars['String']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
}

export type PortabilityContractHolder = {
    firstName: Scalars['String']
    lastName: Scalars['String']
}

export type PortabilityData = {
    address: AddressData
    contractHolderOptions: Array<PortabilityContractHolder>
    endOfContract: Scalars['String']
    phoneOptions: Array<PortabilityTelephone>
    selectedProvider: Scalars['String']
    selectedRadios: Array<Scalars['String']>
    landLineContractOptionsExit: Scalars['Boolean']
}

export type PortabilityTelephone = {
    areaCode: Scalars['String']
    number: Scalars['String']
}

export type PostCodeAddress = {
    __typename?: 'PostCodeAddress'
    country: Scalars['String']
    locality: Scalars['String']
    street: Scalars['String']
    postcode: Scalars['String']
    building: Scalars['String']
    buildingNumber: Scalars['Int']
    buildingNumberAddition: Scalars['String']
}

export type PostCodeCountry = {
    __typename?: 'PostCodeCountry'
    name: Scalars['String']
    iso3Code: Scalars['String']
}

export type PostCodeLocation = {
    __typename?: 'PostCodeLocation'
    latitude: Scalars['Float']
    longitude: Scalars['Float']
    precision: Scalars['String']
}

export type PostCodeDetail = {
    __typename?: 'PostCodeDetail'
    language: Scalars['String']
    location: PostCodeLocation
    country: PostCodeCountry
    mailLines: Array<Scalars['String']>
    address: PostCodeAddress
}

export type PostCodeInput = {
    context: Scalars['String']
    term: Scalars['String']
}

export type PostCodeObject = {
    __typename?: 'PostCodeObject'
    value: Scalars['String']
    label: Scalars['String']
    description: Scalars['String']
    precision: Scalars['String']
    context: Scalars['String']
    highlights: Array<Array<Scalars['Int']>>
}

export type PostCodeResponse = {
    __typename?: 'PostCodeResponse'
    matches: Array<PostCodeObject>
}

export type Product = {
    __typename?: 'Product'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    descriptionBasket: Scalars['String']
    dataSheet?: Maybe<Scalars['String']>
    badgeIdentifier?: Maybe<Scalars['String']>
    subCategory?: Maybe<Scalars['String']>
    productTypes: Array<ProductType>
    contractLength: Scalars['String']
    information?: Maybe<Scalars['String']>
    billMode: BillMode
    included: Scalars['Boolean']
    excludedArticles: Array<Scalars['String']>
    requiredArticles: Array<Scalars['String']>
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discounts: Array<Discount>
    fees: Array<Fee>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
    minimumContractPeriod: Scalars['String']
    cancellationPeriod: Scalars['String']
    contractExtension: Scalars['String']
}

export type ProductCategory = {
    __typename?: 'ProductCategory'
    id: Scalars['ID']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    priceText: Scalars['String']
    products: Array<Product>
    sortIndex: Scalars['Int']
}

export type ProductType = {
    __typename?: 'ProductType'
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dataSheet?: Maybe<Scalars['String']>
    category: Array<OptionCategory>
    billMode?: Maybe<BillMode>
    included?: Maybe<Scalars['Boolean']>
    isDefault?: Maybe<Scalars['Boolean']>
    optional?: Maybe<Scalars['Boolean']>
    gross?: Maybe<Scalars['Float']>
    priceNet?: Maybe<Scalars['Float']>
    priceVat?: Maybe<Scalars['Float']>
    sortIndex: Scalars['Int']
    discounts: Array<Discount>
    footnoteText: Scalars['String']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
}

export type Provisioning = {
    __typename?: 'Provisioning'
    id: Scalars['ID']
    state: Scalars['String']
    orderNr: Scalars['String']
    orderDate: Scalars['Time']
    oneTimeCost: Scalars['Float']
    monthlyCost: Scalars['Float']
    orderedProducts: Array<OrderedProduct>
    activeContract: Scalars['String']
    contractStart: Scalars['Time']
    source: Scalars['String']
    cancelReason: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    availableCities: Array<Scalars['String']>
    availableStreets: Array<Scalars['String']>
    availableHouseNumbers: Array<Scalars['String']>
    availableProducts: AvailableProductsResponse
    checkVZF?: Maybe<CheckVzfResponse>
    generateVZFID: Scalars['String']
    loadOrderProgress?: Maybe<LoadOrderProgressResponse>
    validateManualVoucher?: Maybe<VoucherData>
    validateNonManualVoucher: Array<VoucherData>
    searchForProducts: Array<VoucherProduct>
    searchForDiscounts: Array<VoucherProduct>
    loadUserList: Array<User>
    loadUser: User
    loadLoggedUser: LoggedUser
    loadVouchers: Array<Voucher>
    loadMarketingList: Array<Marketing>
    loadMarketing: Marketing
    loadAddresses: PostCodeResponse
    loadAddressesDetail: PostCodeDetail
    loadBookings: Array<Booking>
    loadProvisionings: Array<Provisioning>
}

export type QueryAvailableCitiesArgs = {
    zipcode: Scalars['String']
}

export type QueryAvailableStreetsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
}

export type QueryAvailableHouseNumbersArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
}

export type QueryAvailableProductsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    B2B: Scalars['Boolean']
    loadTestData?: Maybe<Scalars['Boolean']>
}

export type QueryCheckVzfArgs = {
    vzf: Scalars['String']
}

export type QueryLoadOrderProgressArgs = {
    id: Scalars['String']
}

export type QueryValidateManualVoucherArgs = {
    input: ValidateManualVoucherInput
}

export type QueryValidateNonManualVoucherArgs = {
    input: ValidateNonManualVoucherInput
}

export type QuerySearchForProductsArgs = {
    search: Scalars['String']
}

export type QuerySearchForDiscountsArgs = {
    search: Scalars['String']
}

export type QueryLoadUserArgs = {
    id: Scalars['String']
}

export type QueryLoadLoggedUserArgs = {
    id: Scalars['String']
}

export type QueryLoadMarketingArgs = {
    id: Scalars['String']
}

export type QueryLoadAddressesArgs = {
    input: PostCodeInput
}

export type QueryLoadAddressesDetailArgs = {
    context: Scalars['String']
}

export type Response = {
    __typename?: 'Response'
    status: Scalars['String']
    message?: Maybe<Scalars['String']>
}

export type SelectedOptionCategory = {
    id: Scalars['String']
    selectedOptions: Array<Scalars['String']>
}

export type SelectedProduct = {
    id: Scalars['String']
    productTypes: Array<SelectedProductType>
}

export type SelectedProductCategory = {
    id: Scalars['String']
    selectedProduct?: Maybe<SelectedProduct>
}

export type SelectedProductType = {
    id: Scalars['String']
    optionCategories: Array<SelectedOptionCategory>
}

export type TotalDiscounts = {
    sum: Scalars['String']
    discounts: Array<VzfpdfDiscount>
}

export type TouchPointInput = {
    bookingID: Scalars['String']
    key: Scalars['String']
    value: Scalars['String']
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type UserDb = {
    __typename?: 'UserDB'
    id: Scalars['ID']
    keycloakId: Scalars['ID']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type UserInput = {
    id: Scalars['String']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type VzfData = {
    __typename?: 'VZFData'
    selectedProductCategories: Scalars['String']
    multipleSelectOptionList: Array<MultipleSelectOptions>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    email: Scalars['String']
}

export type VzfDataInput = {
    selectedProductCategories: Scalars['String']
    multipleSelectOptionList: Array<MultipleSelectOptionItem>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    email: Scalars['String']
}

export type VzfpdfCategoryCost = {
    title: Scalars['String']
    sort: Scalars['Int']
    entries: Array<VzfpdfEntry>
}

export type VzfpdfCost = {
    monthlyCost: Scalars['String']
    oneTimeCost: Scalars['String']
    monthlyItems: Array<VzfpdfEntry>
    oneTimeItems: Array<VzfpdfEntry>
    categoryCost: Array<VzfpdfCategoryCost>
    totalDiscounts: TotalDiscounts
}

export type VzfpdfData = {
    vzfID: Scalars['String']
    text: VzfpdfStaticText
    functionalFeaturesDisability: Scalars['String']
    costHint1: Scalars['String']
    costHint2: Scalars['String']
    costHint3: Scalars['String']
    optionalCosts: Array<VzfpdfEntry>
    services: Array<VzfpdfServices>
    devices: Array<Scalars['String']>
    costs: VzfpdfCost
    internet?: Maybe<VzfpdfInternet>
    contractTerms: Array<Scalars['String']>
    products: Array<Scalars['String']>
}

export type VzfpdfDiscount = {
    name: Scalars['String']
    oldPrice: Scalars['String']
    newPrice: Scalars['String']
}

export type VzfpdfEntry = {
    title: Scalars['String']
    value: Scalars['String']
    billMode?: Maybe<BillMode>
}

export type VzfpdfInternet = {
    speedTerm: Scalars['String']
    upload: VzfpdfSpeed
    download: VzfpdfSpeed
}

export type VzfpdfServices = {
    type: Scalars['String']
    title: Scalars['String']
    information: Array<Scalars['String']>
}

export type VzfpdfSpeed = {
    max: Scalars['String']
    normal: Scalars['String']
    min: Scalars['String']
}

export type VzfpdfStaticText = {
    intro: Array<Scalars['String']>
    footer: Array<FooterContent>
}

export type ValidateManualVoucherInput = {
    code: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    products: Array<Scalars['String']>
}

export type ValidateNonManualVoucherInput = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    products: Array<Scalars['String']>
}

export type Voucher = {
    __typename?: 'Voucher'
    active: Scalars['Boolean']
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    isRestrictedLocation: Scalars['Boolean']
    addresses: Array<Address>
    name: Scalars['String']
    products: Array<VoucherProduct>
    actions: Array<VoucherProduct>
    redeemed: Scalars['Int']
    redemptionFrequency: Scalars['Int']
    validFrom?: Maybe<Scalars['Time']>
    dateOfExpiry?: Maybe<Scalars['Time']>
    value: VoucherValue
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export enum VoucherCurrency {
    EURO = 'EURO',
    PERCENT = 'PERCENT',
}

export type VoucherData = {
    __typename?: 'VoucherData'
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    products: Array<VoucherProduct>
    actions: Array<VoucherProduct>
    value: VoucherValue
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherDataInput = {
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    currency: VoucherCurrency
    isOnce: Scalars['Boolean']
    month: Scalars['Int']
    value: Scalars['Float']
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherInput = {
    active: Scalars['Boolean']
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    isRestrictedLocation: Scalars['Boolean']
    addresses: Array<AddressData>
    name: Scalars['String']
    products: Array<VoucherProductInput>
    actions: Array<VoucherProductInput>
    redeemed: Scalars['Int']
    redemptionFrequency: Scalars['Int']
    validFrom?: Maybe<Scalars['Time']>
    dateOfExpiry?: Maybe<Scalars['Time']>
    value: VoucherValueInput
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherProduct = {
    __typename?: 'VoucherProduct'
    articleId: Scalars['String']
    name: Scalars['String']
    price: Scalars['Float']
    type: Scalars['String']
}

export type VoucherProductInput = {
    articleId: Scalars['String']
    name: Scalars['String']
    price: Scalars['Float']
    type: Scalars['String']
}

export type VoucherValue = {
    __typename?: 'VoucherValue'
    currency: VoucherCurrency
    isOnce: Scalars['Boolean']
    month: Scalars['Int']
    value: Scalars['Int']
}

export type VoucherValueInput = {
    currency: VoucherCurrency
    isOnce: Scalars['Boolean']
    month: Scalars['Int']
    value: Scalars['Int']
}
